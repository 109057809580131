'use client';

import React from 'react';
import { useRouter } from 'next/navigation';

import { Box, Button, Colors, FixedBottom, Typography } from '@episode-ui';
import { ErrorRoundedRed56Icon } from 'public/icons';
import nativeutil from '@eppay/utils/nativeutil';

export default function NotFound() {
  const router = useRouter();

  const handleSubmitButtonClick = () => {
    nativeutil.back(router)
  };

  return (
    <Box display={'flex'} flexDirection={'column'} height={'100vh'} bgcolor={Colors.ep_white} alignItems={'center'}>
      <Box marginTop={'120px'} alignItems={'center'} justifyContent={'center'}>
        <ErrorRoundedRed56Icon />
      </Box>
      <Box padding={'8px'} alignItems={'center'}>
        <Typography variant={'h3'} color={Colors.ep_black}>
          다시 한번 확인해주세요 (404)
        </Typography>
      </Box>
      <Box padding={'8px'} alignItems={'center'}>
        <Typography variant={'body2'} whiteSpace={'pre'} textAlign={'center'} color={Colors.ep40}>
          서비스 이용에 불편을 드려 죄송합니다.
          <br />
          요청하신 페이지를 찾을 수 없습니다.
          <br />
          입력하신 주소가 정확한지 다시 한번 확인주세요.
          <br />
        </Typography>
      </Box>
      <FixedBottom>
        <Button size={'lg'} fullWidth onClick={() => handleSubmitButtonClick()}>
          <Typography variant="body1_bold">뒤로가기</Typography>
        </Button>
      </FixedBottom>
    </Box>
  );
}
